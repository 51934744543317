// Handle Carousel controls
const previousButtons = [...document.querySelectorAll("[data-carousel-prev]")];
previousButtons.forEach((button) =>
  button.addEventListener("click", handlePreviousButtonClick)
);

function handlePreviousButtonClick() {
  const prevButton = this;
  const carousel = prevButton.closest("[data-carousel]");
  const trackIndex = getTrackIndex(carousel);
  const totalPages = getTotalPages(carousel);

  if (trackIndex !== 0) setTrackIndex(carousel, trackIndex - 1);
  else setTrackIndex(carousel, totalPages - 1);
}

const nextButtons = [...document.querySelectorAll("[data-carousel-next]")];
nextButtons.forEach((button) =>
  button.addEventListener("click", handleNextButtonClick)
);

function handleNextButtonClick() {
  const nextButton = this;
  const carousel = nextButton.closest("[data-carousel]");
  const trackIndex = getTrackIndex(carousel);
  const totalPages = getTotalPages(carousel);

  if (trackIndex !== totalPages - 1) setTrackIndex(carousel, trackIndex + 1);
  else setTrackIndex(carousel, 0);
}

function getTotalPages(carousel) {
  const totalItems = [...carousel.querySelectorAll("[data-carousel-item")]
    .length;
  const itemsPerPage = parseInt(
    getComputedStyle(carousel).getPropertyValue("--item-count")
  );

  return Math.ceil(totalItems / itemsPerPage);
}

function getTrackIndex(carousel) {
  return parseInt(getComputedStyle(carousel).getPropertyValue("--track-index"));
}

function setTrackIndex(carousel, trackIndex) {
  carousel.style.setProperty("--track-index", trackIndex);
}

// Handle resize event to show the correct carousel slide
window.addEventListener("resize", handleResize);

function handleResize() {
  const carousels = [...document.querySelectorAll("[data-carousel]")];
  carousels.forEach((carousel) => {
    const trackIndex = getTrackIndex(carousel);
    const totalPages = getTotalPages(carousel);
    if (trackIndex >= totalPages) setTrackIndex(carousel, totalPages - 1);
  });
}
